var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('wizard-form',_vm._g(_vm._b({scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',[_c('h1',{staticClass:"primary--text  font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t('transfer_local_panama'))+" ")]),_c('div',{staticClass:"mt-2 mb-4"},[_c('hr',{staticClass:"rounded primary",attrs:{"color":"primary"}}),_c('hr',{staticClass:"primary",attrs:{"size":"1","color":"primary"}})])])]),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('transfer_alias'),"rules":"required|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":((_vm.$t('transfer_alias')) + " *"),"dense":"","autocomplete":"nope","error-messages":errors[0],"maxlength":"50"},model:{value:(_vm.permanentInstruction.alias),callback:function ($$v) {_vm.$set(_vm.permanentInstruction, "alias", $$v)},expression:"permanentInstruction.alias"}})]}}])})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('transfer_account_type'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.items,"label":((_vm.$t('transfer_account_type')) + " *"),"error-messages":errors[0],"dense":"","return-object":"","item-text":"t"},on:{"input":_vm.handleAccountType},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$t(item.t)))])]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$t(item.t)))])]}}],null,true),model:{value:(_vm.permanentInstruction.accountType),callback:function ($$v) {_vm.$set(_vm.permanentInstruction, "accountType", $$v)},expression:"permanentInstruction.accountType"}})]}}])})],1),_c('v-col',{attrs:{"cols":!_vm.isAccountCode && (_vm.isThirdParty || _vm.isExecutive) ? 12 : 11,"md":!_vm.isAccountCode && (_vm.isThirdParty || _vm.isExecutive) ? 6 : 5}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('transfer_name'),"rules":"required|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{ref:"beneficiary",attrs:{"label":((_vm.$t('transfer_name')) + " *"),"dense":"","autocomplete":"nope","error-messages":errors[0],"disabled":_vm.lockedField,"maxlength":"50"},on:{"input":_vm.handleInputs},model:{value:(_vm.permanentInstruction.beneficiary),callback:function ($$v) {_vm.$set(_vm.permanentInstruction, "beneficiary", $$v)},expression:"permanentInstruction.beneficiary"}})]}}])})],1),((!_vm.isThirdParty && !_vm.isExecutive) || _vm.isAccountCode)?_c('v-col',{attrs:{"cols":"1"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","disabled":!_vm.lockedField},on:{"click":_vm.handleUnlock}},on),[_c('v-icon',[_vm._v("mdi-lock-open-variant")])],1)]}}],null,false,2027843903)},[_c('span',[_vm._v(_vm._s(_vm.$t('modify_beneficiary')))])])],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{ref:"email",attrs:{"name":_vm.$t('transfer_email'),"rules":"email|confirmed:correo|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('transfer_email'),"dense":"","autocomplete":"nope","error-messages":errors[0],"maxlength":"50"},model:{value:(_vm.permanentInstruction.bnfEmail),callback:function ($$v) {_vm.$set(_vm.permanentInstruction, "bnfEmail", $$v)},expression:"permanentInstruction.bnfEmail"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('transfer_confirm_email'),"rules":"email|max:50","vid":"correo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('transfer_confirm_email'),"dense":"","autocomplete":"nope","error-messages":errors[0],"maxlength":"50"},model:{value:(_vm.permanentInstruction.bnfEmailConfirm),callback:function ($$v) {_vm.$set(_vm.permanentInstruction, "bnfEmailConfirm", $$v)},expression:"permanentInstruction.bnfEmailConfirm"}})]}}])})],1)],1),_c('v-row',{attrs:{"dense":""}},[(_vm.permanentInstruction.accountType.value == 'nfiAccount')?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('transfer_financial_institution_of_beneficiary'),"rules":"required|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":((_vm.$t('transfer_financial_institution_of_beneficiary')) + " *"),"dense":"","autocomplete":"nope","error-messages":errors[0],"maxlength":"50"},model:{value:(_vm.permanentInstruction.bnfBankName),callback:function ($$v) {_vm.$set(_vm.permanentInstruction, "bnfBankName", $$v)},expression:"permanentInstruction.bnfBankName"}})]}}],null,false,357078797)})],1):_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('transfer_financial_institution_of_beneficiary'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.nationalBanks,"item-text":"prtBankName","item-value":"prtBankName","label":((_vm.$t('transfer_financial_institution_of_beneficiary')) + " *"),"error-messages":errors[0],"dense":""},on:{"input":_vm.handlePartnerBank},model:{value:(_vm.partnerBank),callback:function ($$v) {_vm.partnerBank=$$v},expression:"partnerBank"}})]}}])})],1),(_vm.anotherBank == true)?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('transfer_other_beneficiary_institution'),"rules":"required|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":((_vm.$t('transfer_other_beneficiary_institution')) + " *"),"dense":"","autocomplete":"nope","error-messages":errors[0],"maxlength":"50"},model:{value:(_vm.permanentInstruction.bnfBankName),callback:function ($$v) {_vm.$set(_vm.permanentInstruction, "bnfBankName", $$v)},expression:"permanentInstruction.bnfBankName"}})]}}],null,false,3198650646)})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('transfer_account_number_iban'),"rules":"required|alpha_num|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX'),expression:"'XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX'"}],attrs:{"label":((_vm.$t('transfer_account_number_iban')) + " *"),"dense":"","autocomplete":"nope","error-messages":errors[0],"maxlength":"50"},on:{"input":_vm.handleInputs},model:{value:(_vm.permanentInstruction.bnfBankAccNumber),callback:function ($$v) {_vm.$set(_vm.permanentInstruction, "bnfBankAccNumber", $$v)},expression:"permanentInstruction.bnfBankAccNumber"}})]}}])})],1)],1),(_vm.permanentInstruction.accountType.value == 'nfiAccount')?_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('transfer_receiving_bank'),"rules":"required|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":((_vm.$t('transfer_receiving_bank')) + " *"),"dense":"","autocomplete":"nope","error-messages":errors[0],"maxlength":"50"},model:{value:(_vm.permanentInstruction.nfiName),callback:function ($$v) {_vm.$set(_vm.permanentInstruction, "nfiName", $$v)},expression:"permanentInstruction.nfiName"}})]}}],null,false,2379254911)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('transfer_account_receiving_bank'),"rules":"required|alpha_num|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX'),expression:"'XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX'"}],attrs:{"label":((_vm.$t('transfer_account_receiving_bank')) + " *"),"dense":"","autocomplete":"nope","error-messages":errors[0],"maxlength":"50"},model:{value:(_vm.permanentInstruction.nfiAccountNumber),callback:function ($$v) {_vm.$set(_vm.permanentInstruction, "nfiAccountNumber", $$v)},expression:"permanentInstruction.nfiAccountNumber"}})]}}],null,false,3928860573)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"ABA / SWIFT","dense":"","autocomplete":"nope","maxlength":"50"},model:{value:(_vm.permanentInstruction.bnfBankABA),callback:function ($$v) {_vm.$set(_vm.permanentInstruction, "bnfBankABA", $$v)},expression:"permanentInstruction.bnfBankABA"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('transfer_city'),"rules":"max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('transfer_city'),"dense":"","autocomplete":"nope","error-messages":errors[0],"maxlength":"50"},model:{value:(_vm.permanentInstruction.bnfBankCity),callback:function ($$v) {_vm.$set(_vm.permanentInstruction, "bnfBankCity", $$v)},expression:"permanentInstruction.bnfBankCity"}})]}}],null,false,1804293482)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('transfer_country'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.countries,"item-text":"countryName","item-value":"countryName","label":_vm.$t('transfer_country'),"error-messages":errors[0],"dense":""},on:{"input":function($event){return _vm.selectCountry($event, 'bnfBankCountryCode')}},model:{value:(_vm.permanentInstruction.bnfBankCountry),callback:function ($$v) {_vm.$set(_vm.permanentInstruction, "bnfBankCountry", $$v)},expression:"permanentInstruction.bnfBankCountry"}})]}}],null,false,2950661169)})],1)],1):_vm._e(),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":_vm.$t('transfer_observations'),"dense":"","autocomplete":"nope","disabled":_vm.permanentInstruction.accountType.value == 'nfiAccount',"maxlength":"128"},model:{value:(_vm.permanentInstruction.bnfReference),callback:function ($$v) {_vm.$set(_vm.permanentInstruction, "bnfReference", $$v)},expression:"permanentInstruction.bnfReference"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":_vm.$t('transfer_instructions'),"dense":"","autocomplete":"nope","maxlength":"128"},model:{value:(_vm.permanentInstruction.avsInternalRemark),callback:function ($$v) {_vm.$set(_vm.permanentInstruction, "avsInternalRemark", $$v)},expression:"permanentInstruction.avsInternalRemark"}})],1)],1)]},proxy:true}])},'wizard-form',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }