var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('BaseList',{attrs:{"flat":"","color":"primary","title":_vm.$t('transfer_index_title'),"section":_vm.$route.meta.section,"headers":_vm.headers,"items":_vm.instructions,"loading":_vm.loading,"totalRows":_vm.totalRows,"breadcrumbs":false,"elevation":0,"height":'100%',"buttons":{ search: true, add: (!_vm.isExecutive && !_vm.handleAuthorityAccounts() || _vm.isAccountCode) },"search":_vm.search},on:{"add":_vm.handleNew,"edit":_vm.handleEdit,"refresh":_vm.getData,"delete":_vm.handleDelete,"options":_vm.handleOptions,"search":_vm.handleSearch},scopedSlots:_vm._u([{key:"header.alias",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(_vm.$t('transfer_index_alias'))+" ")])]},proxy:true},{key:"header.trType",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(_vm.$t('transfer_index_type'))+" ")])]},proxy:true},{key:"header.bnfBankName",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(_vm.$t('transfer_index_bank'))+" ")])]},proxy:true},{key:"header.status",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(_vm.$t('transfer_index_status'))+" ")])]},proxy:true},{key:"header.bnfBankAccNumber",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(_vm.$t('transfer_index_account'))+" ")])]},proxy:true},{key:"header.operation",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(_vm.$t('transfer_index_action'))+" ")])]},proxy:true},{key:"item.isThirdParty",fn:function(ref){
var value = ref.value;
return [_c('v-simple-checkbox',{attrs:{"ripple":false,"value":value}})]}},{key:"item.operation",fn:function(ref){
var item = ref.item;
return [(item.isCertified)?_c('span',{staticClass:"d-flex justify-center"},[((item.isThirdParty && !_vm.isExecutive) || !item.isThirdParty)?[(!_vm.handleAuthorityAccounts())?_c('v-btn',{directives:[{name:"can",rawName:"v-can",value:('create-funds-withdrawal-request'),expression:"'create-funds-withdrawal-request'"}],staticClass:"py-4 px-5 mr-3 retire-btn",attrs:{"color":"red","rounded":"","outlined":"","text":"","small":""},on:{"click":function($event){return _vm.handleRetire(item)}}},[_vm._v(" "+_vm._s(_vm.$t('btn_withdraw'))+" ")]):_vm._e(),(!_vm.handleAuthorityAccounts())?_c('v-btn',{directives:[{name:"can",rawName:"v-can",value:('create-notification-funds-deposits'),expression:"'create-notification-funds-deposits'"}],staticClass:"py-4 deposit-btn",attrs:{"color":"success","rounded":"","outlined":"","text":"","small":""},on:{"click":function($event){return _vm.handleDeposit(item)}}},[_vm._v(" "+_vm._s(_vm.$t('btn_deposit'))+" ")]):_vm._e()]:_vm._e()],2):_c('span',[(!_vm.handleAuthorityAccounts() && !_vm.isExecutive)?_c('v-btn',{directives:[{name:"can",rawName:"v-can",value:('certify-permanent-instructions'),expression:"'certify-permanent-instructions'"}],staticClass:"py-4 certified-btn",attrs:{"color":"info","rounded":"","outlined":"","text":"","small":""},on:{"click":function($event){return _vm.handleValidationModal(item)}}},[_vm._v(" "+_vm._s(_vm.$t('btn_pending_certify'))+" ")]):_vm._e()],1)]}},{key:"item.bnfBankName",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.accountType == 'nfiAccount' ? item.nfiName : item.bnfBankName))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.isCertified ? 'Certificada' : 'Debe Certificar'))])]}},{key:"item.bnfBankAccNumber",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.accountType == 'nfiAccount' ? _vm.handleBankAccount(item.nfiAccountNumber, item.isCertified) : _vm.handleBankAccount(item.bnfBankAccNumber, item.isCertified)))])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }