var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('wizard-form',_vm._g(_vm._b({scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',[_c('h1',{staticClass:"primary--text  font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t('transfer_international_account'))+" ")]),_c('div',{staticClass:"mt-2 mb-4"},[_c('hr',{staticClass:"rounded primary",attrs:{"color":"primary"}}),_c('hr',{staticClass:"primary",attrs:{"size":"1","color":"primary"}})])])]),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('transfer_alias'),"rules":"required|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":((_vm.$t('transfer_alias')) + " *"),"dense":"","autocomplete":"nope","error-messages":errors[0],"maxlength":"50"},model:{value:(_vm.permanentInstruction.alias),callback:function ($$v) {_vm.$set(_vm.permanentInstruction, "alias", $$v)},expression:"permanentInstruction.alias"}})]}}])})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('transfer_account_type'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.items,"label":((_vm.$t('transfer_account_type')) + " *"),"error-messages":errors[0],"dense":"","return-object":""},on:{"input":_vm.handleAccountType},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$t(item.t)))])]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$t(item.t)))])]}}],null,true),model:{value:(_vm.permanentInstruction.accountType),callback:function ($$v) {_vm.$set(_vm.permanentInstruction, "accountType", $$v)},expression:"permanentInstruction.accountType"}})]}}])})],1),_c('v-col',{attrs:{"cols":!_vm.isAccountCode && (_vm.isThirdParty || _vm.isExecutive) ? 12 : 11,"md":!_vm.isAccountCode && (_vm.isThirdParty || _vm.isExecutive) ? 6 : 5}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('transfer_name'),"rules":"required|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{ref:"beneficiary",attrs:{"label":((_vm.$t('transfer_name')) + " *"),"dense":"","autocomplete":"nope","error-messages":errors[0],"disabled":_vm.lockedField,"maxlength":"50"},on:{"input":_vm.handleInputs},model:{value:(_vm.permanentInstruction.beneficiary),callback:function ($$v) {_vm.$set(_vm.permanentInstruction, "beneficiary", $$v)},expression:"permanentInstruction.beneficiary"}})]}}])})],1),((!_vm.isThirdParty && !_vm.isExecutive) || _vm.isAccountCode)?_c('v-col',{attrs:{"cols":"1"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","disabled":!_vm.lockedField},on:{"click":_vm.handleUnlock}},on),[_c('v-icon',[_vm._v("mdi-lock-open-variant")])],1)]}}],null,false,2027843903)},[_c('span',[_vm._v(_vm._s(_vm.$t('modify_beneficiary')))])])],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('transfer_address_1'),"rules":"max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('transfer_address_1'),"dense":"","autocomplete":"nope","error-messages":errors[0],"maxlength":"50"},model:{value:(_vm.permanentInstruction.bnfAddres1),callback:function ($$v) {_vm.$set(_vm.permanentInstruction, "bnfAddres1", $$v)},expression:"permanentInstruction.bnfAddres1"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('transfer_address_2'),"rules":"max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('transfer_address_2'),"dense":"","autocomplete":"nope","error-messages":errors[0],"maxlength":"50"},model:{value:(_vm.permanentInstruction.bnfAddres2),callback:function ($$v) {_vm.$set(_vm.permanentInstruction, "bnfAddres2", $$v)},expression:"permanentInstruction.bnfAddres2"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('transfer_country'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.countries,"item-text":"countryName","item-value":"countryName","label":_vm.$t('transfer_country'),"error-messages":errors[0],"dense":""},on:{"input":function($event){return _vm.selectCountry($event, 'bnfCountryCode')}},model:{value:(_vm.permanentInstruction.bnfCountry),callback:function ($$v) {_vm.$set(_vm.permanentInstruction, "bnfCountry", $$v)},expression:"permanentInstruction.bnfCountry"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$t('transfer_state'),"dense":"","autocomplete":"nope","maxlength":"50"},model:{value:(_vm.permanentInstruction.bnfState),callback:function ($$v) {_vm.$set(_vm.permanentInstruction, "bnfState", $$v)},expression:"permanentInstruction.bnfState"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('transfer_city'),"rules":"max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('transfer_city'),"dense":"","autocomplete":"nope","error-messages":errors[0],"maxlength":"50"},model:{value:(_vm.permanentInstruction.bnfCity),callback:function ($$v) {_vm.$set(_vm.permanentInstruction, "bnfCity", $$v)},expression:"permanentInstruction.bnfCity"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('transfer_zip_code'),"rules":"max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('transfer_zip_code'),"dense":"","autocomplete":"nope","error-messages":errors[0],"maxlength":"50"},model:{value:(_vm.permanentInstruction.bnfZipcode),callback:function ($$v) {_vm.$set(_vm.permanentInstruction, "bnfZipcode", $$v)},expression:"permanentInstruction.bnfZipcode"}})]}}])})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$t('transfer_phone'),"dense":"","autocomplete":"nope","maxlength":"50"},model:{value:(_vm.permanentInstruction.bnfPhone),callback:function ($$v) {_vm.$set(_vm.permanentInstruction, "bnfPhone", $$v)},expression:"permanentInstruction.bnfPhone"}})],1)],1),_c('v-row',{attrs:{"dense":""}},[(_vm.permanentInstruction.accountType.value == 'nfiAccount')?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('transfer_financial_institution_of_beneficiary'),"rules":"required|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":((_vm.$t('transfer_financial_institution_of_beneficiary')) + " *"),"dense":"","autocomplete":"nope","error-messages":errors[0],"maxlength":"50"},model:{value:(_vm.permanentInstruction.bnfBankName),callback:function ($$v) {_vm.$set(_vm.permanentInstruction, "bnfBankName", $$v)},expression:"permanentInstruction.bnfBankName"}})]}}],null,false,357078797)})],1):_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('transfer_financial_institution_of_beneficiary'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.internationalBanks,"item-text":"prtBankName","item-value":"prtBankName","label":((_vm.$t('transfer_financial_institution_of_beneficiary')) + " *"),"error-messages":errors[0],"dense":""},on:{"input":_vm.handlePartnerBank},model:{value:(_vm.partnerBank),callback:function ($$v) {_vm.partnerBank=$$v},expression:"partnerBank"}})]}}])})],1),(_vm.anotherBank == true)?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('transfer_other_beneficiary_institution'),"rules":"required|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":((_vm.$t('transfer_other_beneficiary_institution')) + " *"),"dense":"","autocomplete":"nope","error-messages":errors[0],"maxlength":"50"},model:{value:(_vm.permanentInstruction.bnfBankName),callback:function ($$v) {_vm.$set(_vm.permanentInstruction, "bnfBankName", $$v)},expression:"permanentInstruction.bnfBankName"}})]}}],null,false,3198650646)})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('transfer_account_number_iban'),"rules":"required|alpha_num|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX'),expression:"'XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX'"}],attrs:{"label":((_vm.$t('transfer_account_number_iban')) + " *"),"dense":"","autocomplete":"nope","error-messages":errors[0],"maxlength":"50"},on:{"input":_vm.handleInputs},model:{value:(_vm.permanentInstruction.bnfBankAccNumber),callback:function ($$v) {_vm.$set(_vm.permanentInstruction, "bnfBankAccNumber", $$v)},expression:"permanentInstruction.bnfBankAccNumber"}})]}}])})],1)],1),(_vm.permanentInstruction.accountType.value == 'nfiAccount')?_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('transfer_receiving_bank'),"rules":"required|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":((_vm.$t('transfer_receiving_bank')) + " *"),"dense":"","autocomplete":"nope","error-messages":errors[0],"maxlength":"50"},model:{value:(_vm.permanentInstruction.nfiName),callback:function ($$v) {_vm.$set(_vm.permanentInstruction, "nfiName", $$v)},expression:"permanentInstruction.nfiName"}})]}}],null,false,2379254911)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('transfer_account_receiving_bank'),"rules":"required|alpha_num|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX'),expression:"'XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX'"}],attrs:{"label":((_vm.$t('transfer_account_receiving_bank')) + " *"),"dense":"","autocomplete":"nope","error-messages":errors[0],"maxlength":"50"},model:{value:(_vm.permanentInstruction.nfiAccountNumber),callback:function ($$v) {_vm.$set(_vm.permanentInstruction, "nfiAccountNumber", $$v)},expression:"permanentInstruction.nfiAccountNumber"}})]}}],null,false,3928860573)})],1)],1):_vm._e(),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"ABA / SWIFT","rules":"required|alpha_num|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"ABA / SWIFT *","dense":"","autocomplete":"nope","maxlength":"50","error-messages":errors[0]},model:{value:(_vm.permanentInstruction.bnfBankABA),callback:function ($$v) {_vm.$set(_vm.permanentInstruction, "bnfBankABA", $$v)},expression:"permanentInstruction.bnfBankABA"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('transfer_address_1'),"rules":"max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('transfer_address_1'),"dense":"","autocomplete":"nope","error-messages":errors[0],"maxlength":"50"},model:{value:(_vm.permanentInstruction.bnfBankAddres1),callback:function ($$v) {_vm.$set(_vm.permanentInstruction, "bnfBankAddres1", $$v)},expression:"permanentInstruction.bnfBankAddres1"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('transfer_address_2'),"rules":"max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('transfer_address_2'),"dense":"","autocomplete":"nope","error-messages":errors[0],"maxlength":"50"},model:{value:(_vm.permanentInstruction.bnfBankAddres2),callback:function ($$v) {_vm.$set(_vm.permanentInstruction, "bnfBankAddres2", $$v)},expression:"permanentInstruction.bnfBankAddres2"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('transfer_country'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.countries,"item-text":"countryName","item-value":"countryName","label":_vm.$t('transfer_country'),"error-messages":errors[0],"dense":"","maxlength":"50"},on:{"input":function($event){return _vm.selectCountry($event, 'bnfBankCountryCode')}},model:{value:(_vm.permanentInstruction.bnfBankCountry),callback:function ($$v) {_vm.$set(_vm.permanentInstruction, "bnfBankCountry", $$v)},expression:"permanentInstruction.bnfBankCountry"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('transfer_state'),"rules":"max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('transfer_state'),"dense":"","autocomplete":"nope","error-messages":errors[0],"maxlength":"50"},model:{value:(_vm.permanentInstruction.bnfBankState),callback:function ($$v) {_vm.$set(_vm.permanentInstruction, "bnfBankState", $$v)},expression:"permanentInstruction.bnfBankState"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('transfer_city'),"rules":"max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('transfer_city'),"dense":"","autocomplete":"nope","error-messages":errors[0],"maxlength":"50"},model:{value:(_vm.permanentInstruction.bnfBankCity),callback:function ($$v) {_vm.$set(_vm.permanentInstruction, "bnfBankCity", $$v)},expression:"permanentInstruction.bnfBankCity"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":_vm.$t('transfer_observations'),"dense":"","autocomplete":"nope","disabled":_vm.permanentInstruction.accountType.value == 'nfiAccount',"maxlength":"128"},model:{value:(_vm.permanentInstruction.bnfReference),callback:function ($$v) {_vm.$set(_vm.permanentInstruction, "bnfReference", $$v)},expression:"permanentInstruction.bnfReference"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":_vm.$t('transfer_instructions'),"dense":"","autocomplete":"nope","maxlength":"128"},model:{value:(_vm.permanentInstruction.avsInternalRemark),callback:function ($$v) {_vm.$set(_vm.permanentInstruction, "avsInternalRemark", $$v)},expression:"permanentInstruction.avsInternalRemark"}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-switch',{attrs:{"label":_vm.$t('transfer_intermediary')},on:{"change":_vm.handleSwitch},model:{value:(_vm.permanentInstruction.intermBankIsUsed),callback:function ($$v) {_vm.$set(_vm.permanentInstruction, "intermBankIsUsed", $$v)},expression:"permanentInstruction.intermBankIsUsed"}})],1)],1),(_vm.permanentInstruction.intermBankIsUsed)?_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('transfer_intermediary'),"rules":"required|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":((_vm.$t('transfer_intermediary')) + " *"),"dense":"","autocomplete":"nope","error-messages":errors[0],"maxlength":"50"},model:{value:(_vm.permanentInstruction.intermBankName),callback:function ($$v) {_vm.$set(_vm.permanentInstruction, "intermBankName", $$v)},expression:"permanentInstruction.intermBankName"}})]}}],null,false,799944173)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('transfer_intermediary_account'),"rules":"required|alpha_num|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX'),expression:"'XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX'"}],attrs:{"label":((_vm.$t('transfer_intermediary_account')) + " *"),"dense":"","autocomplete":"nope","error-messages":errors[0],"maxlength":"50"},model:{value:(_vm.permanentInstruction.intermBankAccNumber),callback:function ($$v) {_vm.$set(_vm.permanentInstruction, "intermBankAccNumber", $$v)},expression:"permanentInstruction.intermBankAccNumber"}})]}}],null,false,3118426607)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"ABA / SWIFT","rules":"max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"ABA / SWIFT","dense":"","autocomplete":"nope","error-messages":errors[0],"maxlength":"50"},model:{value:(_vm.permanentInstruction.intermBankABA),callback:function ($$v) {_vm.$set(_vm.permanentInstruction, "intermBankABA", $$v)},expression:"permanentInstruction.intermBankABA"}})]}}],null,false,1373208124)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('transfer_address_1'),"rules":"max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('transfer_address_1'),"dense":"","autocomplete":"nope","error-messages":errors[0],"maxlength":"50"},model:{value:(_vm.permanentInstruction.intermBankAddres1),callback:function ($$v) {_vm.$set(_vm.permanentInstruction, "intermBankAddres1", $$v)},expression:"permanentInstruction.intermBankAddres1"}})]}}],null,false,3471084197)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('transfer_address_2'),"rules":"max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('transfer_address_2'),"dense":"","autocomplete":"nope","error-messages":errors[0],"maxlength":"50"},model:{value:(_vm.permanentInstruction.intermBankAddres2),callback:function ($$v) {_vm.$set(_vm.permanentInstruction, "intermBankAddres2", $$v)},expression:"permanentInstruction.intermBankAddres2"}})]}}],null,false,393650277)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('transfer_country'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.countries,"item-text":"countryName","item-value":"countryName","label":_vm.$t('transfer_country'),"error-messages":errors[0],"dense":""},on:{"input":function($event){return _vm.selectCountry($event, 'intermBankCountryCode')}},model:{value:(_vm.permanentInstruction.intermBankCountry),callback:function ($$v) {_vm.$set(_vm.permanentInstruction, "intermBankCountry", $$v)},expression:"permanentInstruction.intermBankCountry"}})]}}],null,false,2462641393)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('transfer_state'),"rules":"max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('transfer_state'),"dense":"","autocomplete":"nope","error-messages":errors[0],"maxlength":"50"},model:{value:(_vm.permanentInstruction.intermBankState),callback:function ($$v) {_vm.$set(_vm.permanentInstruction, "intermBankState", $$v)},expression:"permanentInstruction.intermBankState"}})]}}],null,false,3958112681)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('transfer_city'),"rules":"max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('transfer_city'),"dense":"","autocomplete":"nope","error-messages":errors[0],"maxlength":"50"},model:{value:(_vm.permanentInstruction.intermBankCity),callback:function ($$v) {_vm.$set(_vm.permanentInstruction, "intermBankCity", $$v)},expression:"permanentInstruction.intermBankCity"}})]}}],null,false,2276021225)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":_vm.$t('transfer_intermediary_observations'),"dense":"","autocomplete":"nope","maxlength":"128"},model:{value:(_vm.permanentInstruction.intermReference),callback:function ($$v) {_vm.$set(_vm.permanentInstruction, "intermReference", $$v)},expression:"permanentInstruction.intermReference"}})],1)],1):_vm._e()]},proxy:true}])},'wizard-form',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }