<template>
  <div class="container">
    <v-widget
      :title="$t('menu_transfers_instructions_permanent')"
      :loading="loading"
    >
      <template #content>
        <v-stepper v-model="step" alt-labels flat class="mt-6 elevation-0">
          <v-stepper-header elevation="0" class="elevation-0">
            <template v-for="(item, i) in steps">
              <v-stepper-step
                :key="`${i}-step`"
                :complete="step > i + 1"
                :step="i + 1"
              >
                <span class="text-center">{{ $t(item.title) }}</span>
              </v-stepper-step>
              <v-divider :key="i" v-if="i + 1 < steps.length" />
            </template>
          </v-stepper-header>
          <!-- COMPONENTS -->
          <v-stepper-items>
            <v-stepper-content
              v-for="(item, i) in steps"
              :key="`step-content-${i}`"
              :step="i + 1"
              class="mt-0 pa-0"
            >
              <ValidationObserver ref="observer" v-slot="{ invalid }">
                <component
                  :is="item.component"
                  v-bind="{
                    permanentInstruction,
                    partnerBanks,
                    countries,
                    invalid
                  }"
                  :canPrevious="step > 1"
                  :canNext="step < steps.length"
                  :canSubmit="step === steps.length"
                  @next="step = step + 1"
                  @previous="step = step - 1"
                  @submit="handleSubmit"
                  @cancel="handleCancel"
                  @clear="handleClear"
                  @changeInstructionType="handleInstructionType"
                  @changeAccType="handleAccType"
                  @clearIntermediary="handleClearIntermediary"
                  @selectCountry="handleCountry"
                  @selectPartnerBank="handlePartnerBank"
                  @clearBank="clearBank"
                />
              </ValidationObserver>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </template>
    </v-widget>
  </div>
</template>

<script>
import BaseForm from '@/components/commons/BaseForm'
import VWidget from '@/components/commons/VWidget'
import Info from './partials/Info'
import Type from './partials/Type'
import Nationals from './partials/Nationals'
import Internationals from './partials/Internationals'
import Internal from './partials/Internal'
import Finish from './partials/Finish'
import ClientTransferInstructionsService from '@/services/clientTransferInstructions'
import PartnerBankService from '@/services/partnerBank'
import CountryService from '@/services/country'
import { mapActions, mapState } from 'vuex'
import PublicSettingService from '@/services/publicSetting'
import TwoFactorModal from '@/components/commons/TwoFactorModal.vue'

export default {
  components: {
    BaseForm,
    VWidget,
    Info,
    Type,
    Nationals,
    Internationals,
    Internal,
    Finish
  },
  data() {
    return {
      stepsItems: [
        /*
        {
          title: 'transfer_friendly_banks',
          component: 'Info'
        },
        */
        {
          title: 'transfer_account_type',
          component: 'Type'
        },
        {
          title: 'transfer_account_data',
          component: 'Nationals'
        },
        {
          title: 'transfer_finalize',
          component: 'Finish'
        }
      ],
      states: [],
      step: 1,
      loading: false,
      permanentInstruction: {
        accountType: '',
        fundType: 'cash',
        trType: '',
        beneficiary: '',
        bnfAddres1: '',
        bnfAddres2: '',
        bnfCity: '',
        bnfZipcode: '',
        bnfState: '',
        bnfCountry: '',
        bnfPhone: '',
        bnfEmail: '',
        bnfEmailConfirm: '',
        bnfFurtherCredit: '',
        bnfBankName: '',
        bnfBankAccNumber: '',
        bnfBankABA: '',
        // bnfBankBIC: '',
        bnfBankAddres1: '',
        bnfBankAddres2: '',
        bnfBankCity: '',
        bnfBankState: '',
        bnfBankCountry: '',
        bnfReference: '',
        nfiName: '',
        nfiAccountNumber: '',
        intermBankIsUsed: false,
        intermBankName: '',
        intermBankAccNumber: '',
        intermBankABA: '',
        // intermBankBIC: '',
        intermBankAddres1: '',
        intermBankAddres2: '',
        intermBankCity: '',
        intermBankState: '',
        intermBankCountry: '',
        intermReference: '',
        alias: '',
        avsInternalRemark: '',
        bnfReceivingBank: '',
        bnfReceivingAccNumber: ''
      },
      partnerBanks: [],
      dataCountries: []
    }
  },
  async created() {
    if (this.handleAuthorityAccounts() && !this.isExecutive) {
      this.$router.push({ name: 'Home' })
    }

    await this.getPartnerBanks()
    await this.getCountries()
    await this.getPublicSettings()
    let twoFactor = this.settings.find(
      item => item.key === '2fa_transfer_requests'
    )
    if (twoFactor.value && !this.user.twofactor_habilitated) {
      let response = await this.handleTwoFactorModal(
        this.user.twofactor_habilitated
      )
      if (!response) {
        this.loading = true
        this.$dialog.message.error(this.$t('twofactor_required'))
        this.$router.push({ name: 'Home' })
      }
    }
  },
  computed: {
    ...mapState('account', ['account', 'authorityAccounts', 'accountData']),
    ...mapState('transfer', ['transferRedirect', 'clientRequestData']),
    ...mapState('setting', ['settings']),
    ...mapState('auth', ['user']),

    isExecutive() {
      return this.user?.authorityLevelsCode === 1
    },

    canPrevious() {
      return this.step > 1
    },
    // Dynamic Menu
    steps() {
      let data = [...this.stepsItems]
      let accountType = this.stepsItems.find(
        item => item.title == 'transfer_account_data'
      )

      switch (this.permanentInstruction.trType) {
        case 'local':
          accountType.component = 'Nationals'
          break
        case 'international':
          accountType.component = 'Internationals'
          break
        case 'avsInternal':
          accountType.component = 'Internal'
          break
      }
      return data
    },

    countries() {
      return this.dataCountries
        .map(item => ({
          ...item,
          countryName: this.$t(item.countryName)
        }))
        .sort((a, b) => {
          if (a.countryName < b.countryName) {
            return -1
          }
          if (a.countryName > b.countryName) {
            return 1
          }
          return 0
        })
    }
  },
  methods: {
    ...mapActions('transfer', [
      'setPermanentInstruction',
      'setTransferRedirect'
    ]),
    ...mapActions('setting', ['setSettings']),

    handleAuthorityAccounts() {
      return (
        this.authorityAccounts.findIndex(
          item => item.code == this.account.code
        ) > -1
      )
    },
    handleSubmit: async function() {
      try {
        let twoFactor = this.settings.find(
          item => item.key === '2fa_transfer_requests'
        )
        if (twoFactor.value && this.user.twofactor_habilitated) {
          const response = await this.handleTwoFactorModal(
            this.user.twofactor_habilitated
          )
          if (response == 'reset') {
            this.$dialog.message.error(this.$t('twofactor_required'))
            this.$router.push({ name: 'Delete' })
            throw new Error(this.$t('twofactor_required'))
          }
          if (!response) return
        }

        this.loading = true

        const isCertified = this.isExecutive ? false : true

        let payload = {
          isCertified,
          trType: this.permanentInstruction.trType,
          beneficiary: this.permanentInstruction.beneficiary,
          fundType: this.permanentInstruction.fundType,
          accountType: this.permanentInstruction.accountType.value,
          bnfBankAccNumber: this.permanentInstruction.bnfBankAccNumber,
          bnfAddres1: this.permanentInstruction.bnfAddres1,
          bnfAddres2: this.permanentInstruction.bnfAddres2,
          bnfZipcode: this.permanentInstruction.bnfZipcode,
          bnfCity: this.permanentInstruction.bnfCity,
          bnfState: this.permanentInstruction.bnfState,
          bnfCountry: this.permanentInstruction.bnfCountry,
          bnfCountryCode: this.permanentInstruction.bnfCountryCode,
          bnfPhone: this.permanentInstruction.bnfPhone,
          bnfEmail: this.permanentInstruction.bnfEmail,
          bnfFurtherCredit: this.permanentInstruction.bnfFurtherCredit,
          bnfBankName: this.permanentInstruction.bnfBankName,
          bnfBankABA: this.permanentInstruction.bnfBankABA,
          bnfBankAddres1: this.permanentInstruction.bnfBankAddres1,
          bnfBankAddres2: this.permanentInstruction.bnfBankAddres2,
          bnfBankCity: this.permanentInstruction.bnfBankCity,
          bnfBankState: this.permanentInstruction.bnfBankState,
          bnfBankCountry: this.permanentInstruction.bnfBankCountry,
          bnfBankCountryCode: this.permanentInstruction.bnfBankCountryCode,
          bnfReference: this.permanentInstruction.bnfReference,
          nfiName: this.permanentInstruction.nfiName,
          nfiAccountNumber: this.permanentInstruction.nfiAccountNumber,
          intermBankIsUsed: this.permanentInstruction.intermBankIsUsed,
          intermBankName: this.permanentInstruction.intermBankName,
          intermBankAccNumber: this.permanentInstruction.intermBankAccNumber,
          intermBankABA: this.permanentInstruction.intermBankABA,
          intermBankAddres1: this.permanentInstruction.intermBankAddres1,
          intermBankAddres2: this.permanentInstruction.intermBankAddres2,
          intermBankCity: this.permanentInstruction.intermBankCity,
          intermBankState: this.permanentInstruction.intermBankState,
          intermBankCountry: this.permanentInstruction.intermBankCountry,
          intermBankCountryCode: this.permanentInstruction
            .intermBankCountryCode,
          intermReference: this.permanentInstruction.intermReference,
          avsInternalRemark: this.permanentInstruction.avsInternalRemark,
          alias: this.permanentInstruction.alias,
          isThirdParty: this.permanentInstruction.isThirdParty
        }

        const { data } = await ClientTransferInstructionsService.post(
          this.account.code,
          payload
        )

        if (data.statusCode == 200) {
          this.$dialog.notify.success(this.$t('transfer_instruction_success'))

          if (this.transferRedirect) {
            payload.transferInstructionId = data.transferInstructionId
            this.setPermanentInstruction(payload)
            const name =
              this.clientRequestData.operationType == 'W'
                ? 'RetireCreate'
                : 'DepositCreate'
            this.$router.push({ name })
          } else {
            this.$router.push({ name: 'PermanentInstructions' })
          }
        } else {
          this.$dialog.notify.error(this.$t('transfer_instruction_error'))
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    handleCancel() {
      const { redirect } = this.$route.query
      const routerName = redirect ?? 'PermanentInstructions'
      this.setTransferRedirect(false) // Clear Transfers Requests Steps
      // this.$router.push({ name: 'Home' })
      this.$router.push({ name: routerName })
    },
    async handleClear() {
      this.permanentInstruction.beneficiary = ''
      this.permanentInstruction.bnfBankAccNumber = ''
      this.permanentInstruction.bnfAddres1 = ''
      this.permanentInstruction.bnfAddres2 = ''
      this.permanentInstruction.bnfZipcode = ''
      this.permanentInstruction.bnfCity = ''
      this.permanentInstruction.bnfState = ''
      this.permanentInstruction.bnfCountry = ''
      this.permanentInstruction.bnfCountryCode = ''
      this.permanentInstruction.bnfPhone = ''
      this.permanentInstruction.bnfEmail = ''
      this.permanentInstruction.bnfEmailConfirm = ''
      this.permanentInstruction.bnfFurtherCredit = ''
      this.permanentInstruction.bnfBankName = ''
      this.permanentInstruction.bnfBankABA = ''
      this.permanentInstruction.bnfBankAddres1 = ''
      this.permanentInstruction.bnfBankAddres2 = ''
      this.permanentInstruction.bnfBankCity = ''
      this.permanentInstruction.bnfBankState = ''
      this.permanentInstruction.bnfBankCountry = ''
      this.permanentInstruction.bnfBankCountryCode = ''
      this.permanentInstruction.bnfReceivingBank = ''
      this.permanentInstruction.bnfReceivingAccNumber = ''
      this.permanentInstruction.bnfReference = ''
      this.permanentInstruction.nfiName = ''
      this.permanentInstruction.nfiAccountNumber = ''
      this.permanentInstruction.intermBankIsUsed = false
      this.permanentInstruction.intermBankName = ''
      this.permanentInstruction.intermBankAccNumber = ''
      this.permanentInstruction.intermBankABA = ''
      this.permanentInstruction.intermBankAddres1 = ''
      this.permanentInstruction.intermBankAddres2 = ''
      this.permanentInstruction.intermBankCity = ''
      this.permanentInstruction.intermBankState = ''
      this.permanentInstruction.intermBankCountry = ''
      this.permanentInstruction.intermBankCountryCode = ''
      this.permanentInstruction.intermReference = ''
      this.permanentInstruction.avsInternalRemark = ''
      this.permanentInstruction.isThirdParty = false
    },
    handleInstructionType() {
      this.handleClear()
      this.permanentInstruction.accountType = {}
      if (this.permanentInstruction.trType == 'avsInternal') {
        this.permanentInstruction.bnfBankName = 'Av Securities Inc'
        this.permanentInstruction.accountType = {
          value: 'avsInternal',
          t: 'transfer_internal_account'
        }
      }
      // Account By Default (SameName)
      const fullName = this.account.firstname + ' ' + this.account.lastname
      this.permanentInstruction.beneficiary = fullName

      if (this.accountData) {
        const { email } = this.accountData
        this.permanentInstruction.bnfEmail = email
        this.permanentInstruction.bnfEmailConfirm = email
      }
    },
    handleAccType() {
      this.permanentInstruction.bnfReference = `Credit account ${this.permanentInstruction.bnfBankAccNumber} of ${this.permanentInstruction.beneficiary} at ${this.permanentInstruction.bnfBankName}`
    },
    handleClearIntermediary() {
      this.permanentInstruction.intermBankIsUsed = false
      this.permanentInstruction.intermBankName = ''
      this.permanentInstruction.intermBankAccNumber = ''
      this.permanentInstruction.intermBankABA = ''
      // this.permanentInstruction.intermBankBIC = ''
      this.permanentInstruction.intermBankAddres1 = ''
      this.permanentInstruction.intermBankAddres2 = ''
      this.permanentInstruction.intermBankCity = ''
      this.permanentInstruction.intermBankState = ''
      this.permanentInstruction.intermBankCountry = ''
      this.permanentInstruction.intermReference = ''
    },
    handleCountry(data) {
      if (data.model == 'bnfCountryCode')
        this.permanentInstruction.bnfCountryCode = data.code

      if (data.model == 'bnfBankCountryCode')
        this.permanentInstruction.bnfBankCountryCode = data.code

      if (data.model == 'intermBankCountryCode')
        this.permanentInstruction.intermBankCountryCode = data.code
    },
    handlePartnerBank(bank) {
      this.permanentInstruction.bnfBankName = bank
    },
    clearBank() {
      this.permanentInstruction.bnfBankName = ''
    },
    async getPartnerBanks() {
      this.loading = true
      try {
        const { data } = await PartnerBankService.fetch()
        this.partnerBanks = data
      } catch (error) {
        if (error.response.status !== 401)
          this.$dialog.message.error('No se pudo cargar la información.')
      } finally {
        this.loading = false
      }
    },
    async getCountries() {
      this.loading = true
      try {
        const { data } = await CountryService.fetch({ limit: 9999 })
        this.dataCountries = data.data
      } catch (error) {
        if (error.response.status !== 401)
          this.$dialog.message.error('No se pudo cargar la información.')
      } finally {
        this.loading = false
      }
    },
    async handleTwoFactorModal(twofactor) {
      const params = {
        fullscreen: this.$vuetify.breakpoint.smAndDown,
        width: twofactor ? '40%' : '80%',
        transition: 'dialog-top-transition',
        persistent: false,
        hideOverlay: false,
        twoFactor: twofactor,
        hideSubmit: true,
        showClose: twofactor
      }
      let response = await this.$dialog.showAndWait(TwoFactorModal, params)
      return response
    },
    async getPublicSettings() {
      const { data } = await PublicSettingService.fetch({})
      this.setSettings(data)
    }
  }
}
</script>
<style scoped>
.container {
  max-width: 1264px;
}
</style>
